import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';

import TutorBio from '../components/tutors/tutorBio';
import Title from '../components/text/title';
import Alert from '../components/text/alert';
import Gallery from '../components/images/gallery';
import TextboxWithHeading from '../components/text/textboxWithHeading';
import { Line } from '../components/utility/line';

const Theme = {
  highlightColor: '#96e623',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#e6ffaa',
};

const TutorGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AlumniColumn = styled.div`
  font-size: 20px;

  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

const AlumniYear = styled.h3`
  color: ${props => props.color};
`;

const Alumnus = styled.div`
  & a {
    text-decoration: none;
  }
`;

const Atelier = ({ data, location }) => {
  // const guestTutors = data.prismicAtelier.data.select_guest_tutors.map(
  //   (tutor, index) => {
  //     return (
  //       <TutorBio
  //         key={index}
  //         link={tutor.tutor.uid}
  //         details={tutor.tutor.document.data}
  //       />
  //     );
  //   }
  // );

  const atelierTutors = data.prismicAtelier.data.select_past_tutors
    .filter((tutor, index) => tutor.tutor.document !== null)
    .map((tutor, index) => {
      return (
        <TutorBio
          key={index}
          link={tutor.tutor.uid}
          details={tutor.tutor.document.data}
        />
      );
    });

  const allAlumni = data.prismicAtelier.data.all_alumni.map(
    (alumniYear, index) => {
      return (
        <Col col={12} sm={6} xl={4} key={index}>
          <AlumniColumn>
            <AlumniYear color={Theme.highlightColor}>
              {alumniYear.year.text}
            </AlumniYear>
            <Alumnus
              dangerouslySetInnerHTML={{
                __html: alumniYear.alumni_text.html,
              }}
            />
            <br />
            <br />
          </AlumniColumn>
        </Col>
      );
    }
  );

  return (
    <>
      <Helmet>
        <title>{data.prismicAtelier.data.title.text}</title>
        <meta name="title" content={data.prismicAtelier.data.title.text} />
        <meta
          name="description"
          content={data.prismicAtelier.data.about.text}
        />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk' + location.pathname}
        />
        <meta
          property="og:image"
          content={data.prismicAtelier.data.gallery[0].gallery_image.url}
        />
        <meta
          property=" og:image:secure_url"
          content={data.prismicAtelier.data.gallery[0].gallery_image.url}
        />
        <meta
          property="og:description"
          content={data.prismicAtelier.data.about.text}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta
          name="twitter:title"
          content={data.prismicAtelier.data.title.text}
        />
        <meta
          name="twitter:description"
          content={data.prismicAtelier.data.about.text}
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:image"
          content={data.prismicAtelier.data.gallery[0].gallery_image.url}
        />
      </Helmet>

      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Title
              margin={'80px 0 36px 0'}
              text={data.prismicAtelier.data.title.text}
              case="uppercase"
            />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <Alert
            margin={'0 36px 54px 36px'}
            text={data.prismicAtelier.data.alert.html}
            case="inherit"
            color={Theme.highlightColor}
          />
        </Col>

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading
            heading="ABOUT"
            text={data.prismicAtelier.data.about.html}
            padding={'0 0 54px 0'}
          />
        </ContentContainer>

        <Gallery
          margin={'0 0 54px 0'}
          images={data.prismicAtelier.data.gallery}
        />

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading
            heading="HOW TO APPLY"
            text={data.prismicAtelier.data.how_to_apply.html}
            padding={'0 0 54px 0'}
          />
        </ContentContainer>

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading
            heading="ATELIER TUTORS"
            text={data.prismicAtelier.data.past_tutors.html}
            padding={'0 0 54px 0'}
          />
        </ContentContainer>

        <Col col={12}>
          <ContentContainer padding={'0 22.5px'} mobilePadding={'0 0 20px 0'}>
            <TutorGrid>{atelierTutors}</TutorGrid>
          </ContentContainer>
        </Col>

        {/* <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading
            heading="GUEST TUTORS"
            text={data.prismicAtelier.data.guest_tutors.html}
            padding={'0 0 54px 0'}
          />
        </ContentContainer>

        <Col col={12}>
          <ContentContainer padding={'0 22.5px'} mobilePadding={'0 0 20px 0'}>
            <TutorGrid>{guestTutors}</TutorGrid>
          </ContentContainer>
        </Col> */}

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Row>
              <Col col={12} md={4} lg={3}>
                <Row>
                  <TextboxWithHeading heading="ALUMNI" padding={'0'} />
                </Row>
              </Col>

              <Col col={12} md={8} lg={9}>
                <Row>{allAlumni}</Row>
              </Col>
            </Row>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  query AtelierQuery {
    prismicAtelier {
      data {
        title {
          text
        }
        alert {
          html
        }
        about {
          html
          text
        }
        gallery {
          gallery_image {
            url
            copyright
            dimensions {
              width
              height
            }
          }
        }
        how_to_apply {
          html
        }
        past_tutors {
          html
        }
        select_past_tutors {
          tutor {
            uid
            document {
              ... on PrismicTutors {
                data {
                  name {
                    text
                  }
                  image {
                    url
                  }
                }
              }
            }
          }
        }
        all_alumni {
          year {
            text
          }
          alumni_text {
            html
          }
        }
      }
    }
  }
`;

export default Atelier;
