import React from 'react';
import styled from 'styled-components';

const AlertHeading = styled.div`
  font-size: 28px;

  color: ${props => props.color};

  text-align: center;
  text-transform: ${props => props.case || 'uppercase'};

  margin: ${props => props.margin || '0 0 75px 0'};

  & > p {
    margin: 0;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    margin: 0 0 40px 0;
  }
`;

const Alert = props => (
  <AlertHeading
    margin={props.margin}
    case={props.case}
    color={props.color}
    dangerouslySetInnerHTML={{
      __html: props.text,
    }}
  />
);

export default Alert;
